/**
 *头部
 */
Vue.component('page-header', {
  template:
    '<div class="index-header  container " :class="{fixed:fixed,white:isWhite}">\n' +
    ' <div class="left-box">\n' +
    '     <img class="logo logo-black" src="/images/logo.png" alt="">\n' +
    '     <img class="logo logo-blue" src="/images/logo2.png" alt="">\n' +
    '     <ul class="entry" v-if="!isSearch">\n' +
    '     <li><a class="g" :class="{active:type==1}" href="/" target="_blank">首页</a></li>\n' +
    '     <li><a class="g" :class="{active:type==2}" href="/course.html" target="_blank">课程</a></li>\n' +
    '     <li><a class="g" :class="{active:type==3}" href="/material.html" target="_blank">资料库</a></li>\n' +
    '     <li><a class="g" :class="{active:type==4}" href="/information.html" target="_blank">资讯</a></li>\n' +
    '     <li><a class="g" :class="{active:type==5}" href="/ehs.html" target="_blank">EHS培训</a></li>\n' +
    '     <li><a class="g" :class="{active:type==6}" href="/expert.html" target="_blank">专家</a></li>\n' +
    '     <li><a class="g" :class="{active:type==7}" href="/discuss.html" target="_blank">问答</a></li>\n' +
    '     <li><a class="g" :class="{active:type==8}" href="/wiki.html" target="_blank">行业百科</a></li>\n' +
    '     </ul>\n' +
    '     <div class="search-conetnt" v-else>\n' +
    '     <div class="m" @click="isSearch= false"></div>\n' +
    '     <div class="serarch-box" :style="{\'border-bottom-left-radius\':isType?\'0\':\'\'}">\n' +
    '         <div class="type-box">\n' +
    '         <div class="type" @click="openType" ref="typeBox">\n' +
    '             <div>{{serarchTypeList[serarchTypeIndex].name}}</div>\n' +
    '             <span class="iconfont icon-xiangxia1"></span>\n' +
    '         </div>\n' +
    '         <div class="s-list" v-if="isType">\n' +
    '             <div class="g" :class="{active:index==serarchTypeIndex}" @click="setTypeIndex(index)" v-for="(item,index) in serarchTypeList" :key="index">{{item.name}}</div>\n' +
    '         </div>\n' +
    '         </div>\n' +
    '         <input class="input" type="text" v-model.trim="searchValue" placeholder="请输入搜索内容" ref="inputRef"  @keyup.enter="inputSubmit">\n' +
    '         <span class="iconfont icon-sousuo search-icon"></span>\n' +
    '     </div>\n' +
    '     </div>\n' +
    ' </div>\n' +
    ' <div class="right-box">\n' +
    '     <span class="iconfont icon-sousuo search-img"  v-if="!isSearch" @click="setIsSearch(true)"></span>\n' +
    '     <a href="/vip.html" target="_blank"><img class="vip-img" src="/images/index-vip-icon.png" alt=""></a>\n' +
    '     <div v-if="user.id" class="user-box">\n' +
    '       <div class="avatar-box">\n' +
    '         <a class="avatar" href="/user/info.html" target="_blank">\n' +
    '             <img class="img" v-defaultimg="\'16px\'" :src="user.logo" alt="">\n' +
    '             <div class="name text-overstep">{{user.nickname || \'暂无昵称\'}}</div>\n' +
    '             <span class="iconfont icon-xiangxia1 arrow"></span>\n' +
    '         </a>\n' +
    '         <div class="fun-list">\n' +
    '              <div class="g text-overstep" @click="logout">退出登录</div>\n' +
    '          </div>\n' +
    '       </div>\n' +
    '       <div class="line"></div>\n' +
    '       <div class="avatar-box">\n' +
    '         <a class="avatar" href="/enterprise/set.html" target="_blank" v-if="currentCompay.id">\n' +
    '           <span class="iconfont icon-qiye2 qiye-icon"></span>\n' +
    '           <a class="name enterprise text-overstep " target="_blank">{{currentCompay.company_name}}</a>\n' +
    '           <span class="iconfont icon-xiangxia1 arrow" v-if="companyList.length>1"></span>\n' +
    '         </a>\n' +
    '         <a class="avatar" href="/enterprise/empty.html" target="_blank" v-else>\n' +
    '           <span class="iconfont icon-qiye2 qiye-icon"></span>\n' +
    '           <a class="name enterprise text-overstep " target="_blank">暂无企业</a>\n' +
    '         </a>\n' +
    '         <div class="fun-list" v-if="companyList.length>1">\n' +
    '           <div class="g text-overstep" @click="selectEnterprise(index)" :class="{active:currentCompay.id==item.id}" v-for="(item,index) in companyList" :key="index">{{item.company_name}}</div>\n' +
    '         </div>\n' +
    '       </div>\n' +
    '     </div>\n' +
    '     <template v-else>\n' +
    '       <div class="login-btn active" href="" @click="openLoginPopup(1)">登录</div>\n' +
    '       <div class="login-btn" href="" @click="openLoginPopup(2)">注册</div>\n' +
    '     </template>\n' +
    ' </div>\n' +
    ' <login-form :vshow.sync="loginPopup" :type-prop.sync="typeProp" login-type-prop="1" :needlogin="needlogin"></login-form>\n' +
    ' </div>',
  props: {
    fixed: {
      type: [Boolean],
      default: false,
    },
    white: {
      type: [Boolean],
      default: true,
    },
    isloginpopup: {
      type: [Boolean],
      default: false,
    },
    type: {
      type: [Number, String],
      default: -1,
    },
    needlogin: {
      type: [Boolean],
      default: false,
    }
  },
  data: function () {
    return {
      isWhite: this.white,

      loginPopup: false,
      user: {
      },
      isSearch: false,
      searchValue: '',
      isType: false,
      serarchTypeList: [
        {
          name: '课程',
          value: 1,
          href: '/course.html'
        },
        {
          name: '资料',
          value: 2,
          href: '/material.html'
        }
      ],
      serarchTypeIndex: 0,
      typeProp: 1,
      companyList: [],
      currentCompay: {}
    }
  },
  watch: {
    white: {
      handler: function (val) {
        this.isWhite = val;
      },
      immediate: true,
    },
    isloginpopup: {
      handler: function (val) {
        this.loginPopup = val;
      },
      immediate: true,
    },
    loginPopup(val) {
      if (!val) {
        this.$emit('update:isloginpopup', val)
      }
    }
  },
  created: function () {
    this.checkCookies();
  },
  mounted: function () {
    if (this.fixed) {
      window.addEventListener('scroll', this.navWatch)
    }
  },
  beforeDestroy() {
    if (this.fixed) {
      window.removeEventListener('scroll', this.navWatch)
    }
  },
  methods: {
    navWatch() {
      var that = this;
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      if (that.isWhite) {
        if (scrollTop <= 98) {
          that.isWhite = false;
        }
      } else {
        if (scrollTop > 98) {
          that.isWhite = true;
        }
      }
    },
    openType() {
      this.isType = !this.isType;
      var el = this.$refs.typeBox;
      var that = this;
      function callback(e) {
        if (el.contains(e.target)) {
          that.isType = true;
        } else {
          that.isType = false;
        }
        document.body.removeEventListener('click', callback, true)
      }
      if (this.isType) {
        document.body.addEventListener('click', callback, true)
      }
    },
    setTypeIndex(serarchTypeIndex) {
      if (this.serarchTypeIndex == serarchTypeIndex) { return }
      this.serarchTypeIndex = serarchTypeIndex;
    },
    setIsSearch(val) {
      this.isSearch = val;
      if (val) {
        this.$nextTick(function () {
          this.$refs.inputRef.focus();
        })
      }
    },
    inputSubmit() {
      if (!this.searchValue) { return }
      var href = this.serarchTypeList[this.serarchTypeIndex].href;
      href = href + '?search=' + this.searchValue;
      window.open(href)
    },
    openLoginPopup(typeProp) {
      this.loginPopup = true;
      this.typeProp = typeProp
    },
    selectEnterprise(index) {
      this.currentCompay = this.companyList[index];
      localStorage.setItem('companyId', this.currentCompay.id);
      location.reload()
    },
    logout(){
      localStorage.setItem('Authorization', '')
      window.location.reload();
    },
    checkCookies: function () {
      let that = this;
      axios.get('/user/user/getUserBaseInfo').then(function (res) {
        // console.log(res, 'res2')
        if (res.status == 100) {
          var data = res.data;
          that.user = data;
          Vue.prototype.isLogin = data;
          that.getCompanyList();
          that.$emit('success', data)
          window.eventBus.$emit('loginSuccess', data)
        } else {
          if (that.needlogin) {
            that.loginPopup = true;
          }
          that.$emit('success', {})
          // that.loginPopup = true;
        }
      }).catch(function (err) {
        if (that.needlogin) {
          that.loginPopup = true;
        }
        that.$emit('success',{})
      })
    },
    getCompanyList() {
      let that = this;
      axios.post('/company/company/companyList').then(function (res) {
        // console.log(res,'res')
        let list = res.data;
        if (list.length > 0) {

          var companyId = localStorage.getItem('companyId');
          if (!companyId) {
            that.currentCompay = list[0]
            localStorage.setItem('companyId', that.currentCompay.id);
          } else {
            let total = 0;
            for (let i = 0; i < list.length; i++) {
              if (list[i].id == companyId) {
                that.currentCompay = list[i]
                break;
              } else {
                total++
              }
            }
            if (total == list.length) {
              that.currentCompay = list[0]
              localStorage.setItem('companyId', that.currentCompay.id);
              location.reload()
            }
          }
          window.eventBus.$emit('compaySuccess', list);
        }
        that.companyList = list;
      })
    },
  }
})


/**
 *尾部
 */
Vue.component('page-footer', {
  template:
    '<footer class="footer">\n' +
    '  <div class="">\n' +
    '    <div class="top-box">\n' +
    '      <ul class="ul">\n' +
    '        <li class="li"><a class="a" href="" target="_blank"><span class="span">在线题库</span></a></li>\n' +
    '        <li class="li"><a class="a" href="/course.html" target="_blank"><span class="span">课程</span></a></li>\n' +
    '        <li class="li"><a class="a" href="/material.html" target="_blank"><span class="span">资料</span></a></li>\n' +
    '        <li class="li"><a class="a" href="/help/about.html" target="_blank"><span class="span">公司简介</span></a></li>\n' +
    '        <li class="li"><a class="a" href="/help/contact.html" target="_blank"><span class="span">联系我们</span></a></li>\n' +
    '      </ul>\n' +
    '      <div class="right">\n' +
    '        <div class="phone-box">\n' +
    '          <span>咨询热线</span>\n' +
    '          <span class="phone">400-0366-886</span>\n' +
    '        </div>\n' +
    '        <div class="mp-box">\n' +
    '          <span class="iconfont icon-weixin2"></span>\n' +
    '          <span class="">微信公众号</span>\n' +
    '          <div class="code-box">\n' +
    '            <div class="desc">扫一扫二维码</div>\n' +
    '            <div class="desc">关注安全服务网微信公众号</div>\n' +
    '            <img class="img" src="/images/qrcode-aqfw.png" alt="">\n' +
    '          </div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '    <div class="bottom-tips">版权所有©2006-2023安全服务网（www.anqfw.com） All Rights Reserved ICP许可证：京ICP备2022020722号 <a href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">京ICP备2022020722号-1</a> 公司地址：北京市昌平区石坊院21号12层1207室</div>\n' +
    '  </div>\n' +
    '</footer>',
})



/**
 *分页
 *@prop {number, string} currentPage 当前页数
 *@prop {number, string} totalPage 总页数
 */
Vue.component('pagination', {
  template:
    '<div class="pagination-wrap">\n' +
    '    <div class="pagination clearfix" >\n' +
    '            <div class="pages fl clearfix">\n' +
    '                <div class="btn prev fl" title="上一页" :class="{disabled: page == 1}" @click="turn(-1)">\n' +
    '                    <span class="iconfont icon-shangyiye"></span>\n' +
    '                </div>\n' +
    '                <div class="btn number fl" v-if="totalPage - page < 1 && page > 4" @click="turn(-4)">{{page - 4}}</div>\n' +
    '                <div class="btn number fl" v-if="totalPage - page < 2 && page > 3" @click="turn(-3)">{{page - 3}}</div>\n' +
    '                <div class="btn number fl" v-if="totalPage - page < 3 && page > 2" @click="turn(-2)">{{page - 2}}</div>\n' +
    '                <div class="btn number fl" v-if="totalPage - page < 4 && page > 1" @click="turn(-1)">{{page - 1}}</div>\n' +
    '                <div class="btn number fl active">{{page}}</div>\n' +
    '                <div class="btn number fl" v-if="totalPage - page > 1" @click="turn(1)">{{page + 1}}</div>\n' +
    '                <div class="btn number fl" v-if="totalPage - page > 2" @click="turn(2)">{{page + 2}}</div>\n' +
    '                <div class="btn number fl" v-if="totalPage - page == 4" @click="turn(3)">{{page + 3}}</div>\n' +
    '                <div class="btn number disabled fl" v-if="totalPage - page > 4">...</div>\n' +
    '                <div class="btn number fl" v-if="page < totalPage" @click="turnLast">{{totalPage}}</div>\n' +
    '                <div class="btn next fl" title="下一页" :class="{disabled: page  == totalPage}" @click="turn(1)">\n' +
    '                    <span class="iconfont icon-xiayiye"></span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>',
  props: {
    currentPage: [Number, String],
    totalPage: [Number, String],
  },
  data: function () {
    return {
      page: this.currentPage,
      turnPage: ''
    }
  },
  watch: {
    currentPage: function (newVal) {
      this.page = newVal;
    }
  },
  methods: {
    turn: function (number) {
      if (this.page == 1 && number == -1) {
        return;
      }
      if (this.page == this.totalPage && number == 1) {
        return;
      }
      this.page += number;
      this.$emit('turn-page', this.page);
    },
    turnLast: function () {
      this.page = this.totalPage;
      this.$emit('turn-page', this.page);
    },
    turnTo: function () {
      if (!this.turnPage || this.turnPage == this.page) {
        return;
      }
      this.page = this.turnPage;
      this.turnPage = '';
      this.$emit('turn-page', this.page);
    },
    checkTurnPage: function () {
      this.turnPage = parseInt(this.turnPage);
      if (this.turnPage < 1) {
        this.turnPage = 1;
      }
      if (this.turnPage > this.totalPage) {
        this.turnPage = this.totalPage;
      }
    },
  }
});


/**
 *select筛选器 
 *value 千万别是空字符串 或者 0
 * 
 */
Vue.component('j-option', {
  template: '<li class="option" :class="{active: itemSelected}" @click="selectItem(2)">{{currentLabel}}</li>',
  props: {
    value: {
      required: true
    },
    label: [String, Number],
    index: [String, Number],
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentLabel: function () {
      return this.label || (this.isObject ? '' : this.value);
    },
    itemSelected: function () {
      if (this.$parent.multiple) {
        var value = this.value;
        var res = this.$parent.itemlist.find(function (el, index) {
          return el.value == value
        });
        return res
      } else {
        return this.value == this.$parent.value;
      }
    },
  },
  data: function () {
    return {
    }
  },
  created: function () {
    if (this.$parent.multiple) {
      if (this.$parent.value && Array.isArray(this.$parent.value)) {
        var value = this.value;
        var res = this.$parent.value.find(function (el, index) {
          return el == value
        });

        if (res) {
          this.selectItem(3)
        }
      }
    } else {
      if (this.$parent.value && this.value == this.$parent.value) {
        this.selectItem()
      }
    }

  },
  methods: {
    selectItem: function (type) {
      if (this.$parent.multiple) {
        if (type == 2) {
          var i = -1
          var value = this.value;
          var res = this.$parent.itemlist.find(function (el, index) {
            if (el.value == value) {
              i = index
              return true
            }
          });
          if (i > -1) {
            this.$parent.itemlist.splice(i, 1)
          } else {
            this.$parent.itemlist.push({ value: this.value, label: this.label, index: this.index })
          }
        }
        if (type == 2) {
          let list = this.$parent.itemlist;
          var arr = []
          for (var i = 0; i < list.length; i++) {
            arr.push(list[i].value)
          }
          this.$parent.$emit('input', arr)
          this.$parent.$emit('change', JSON.parse(JSON.stringify(list)))
        }

        if (type == 3) {
          var i = -1
          var value = this.value;
          var res = this.$parent.value.find(function (el, index) {
            if (el == value) {
              i = index
              return true
            }
          });
          if (i > -1) {
            this.$parent.itemlist.push({ value: this.value, label: this.label, index: this.index })
          }
        }
      } else {
        this.$parent.sItem = { value: this.value, label: this.label, index: this.index };
      }
    },
  }
})
Vue.component('j-select', {
  template:
    '<div class="j-select-box" :class="{disabled:disabled}">\n' +
    '    <div class="name-box" @click="openType(!isOpen)" ref="dom">\n' +
    '      <template v-if="multiple">\n' +
    '        <div class="name" v-if="itemlist.length>0">\n' +
    '          <span class="tag" v-for="(it,i) in itemlist" :key="i">{{it.label}}</span>\n' +
    '        </div>\n' +
    '        <div class="name placeholder" v-else>{{placeholder}}</div>\n' +
    '      </template>\n' +
    '      <template v-else>\n' +
    '        <div class="name" v-if="sItem.label">{{sItem.label}}</div>\n' +
    '        <div class="name placeholder" v-else>{{placeholder}}</div>\n' +
    '      </template>\n' +
    '        <div class="icon-box" v-if="!multiple&&colse&&value" @click.stop.prevent="close">\n' +
    '            <span class="iconfont icon-guanbi close" ></span>\n' +
    '        </div>\n' +
    '        <div class="icon-box" v-else>\n' +
    '            <span class="iconfont icon-xiangxia1 arrow" :class="{rotate:isOpen}" ></span>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="type-list" v-show="isOpen">\n' +
    '        <slot v-if="$slots.default"></slot>\n' +
    '        <li class="empty" v-else>暂无数据</li>\n' +
    '    </div>\n' +
    '</div>',
  props: {
    value: {
      required: true
    },
    label: [String, Number],
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    colse: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
  },
  created() {
  },
  watch: {
    value: function (val, oldVal) {
      if (this.multiple) {
        if (val != oldVal) {
          this.itemlist = []
          if (val.length > 0) {
            let childList = this.$children || [];
            for (let i = 0; i < childList.length; i++) {
              let item = childList[i];
              if (val.includes(item.value)) {
                item.selectItem(3);
              } else {

              }
            }
          }
        }
      } else {
        if (!val) {
          this.sItem = {}
        } else {
          let childList = this.$children || [];
          for (let i = 0; i < childList.length; i++) {
            let item = childList[i];
            if (item.value == val) {
              item.selectItem();
              break
            }
          }
        }
      }
    },
    sItem(val, oldVal) {
      if (!val.value) { return }
      if (val.value == this.value) { return }
      this.$emit('input', val.value)
      this.$emit('change', val)
    },

  },
  data: function () {
    return {
      isOpen: false,
      sItem: {},
      itemlist: []
    }
  },
  methods: {
    openType: function (val, e) {
      if (this.disabled) { return }
      this.isOpen = val;
      var that = this;
      var el = this.$refs.dom;
      function callback(e) {
        if (el.contains(e.target)) {
          that.isOpen = true;
        } else {
          that.isOpen = false;
        }
        document.body.removeEventListener('click', callback, true)
      }
      if (val) {
        document.body.addEventListener('click', callback, true)
      }
    },
    close: function () {
      this.isOpen = false;
      if (this.multiple) {
        // console.log(1, '1')
        this.$emit('input', [])
      } else {
        this.$emit('input', '')
      }
    }
  }
})


/**
 *个人中心的左侧导航
 *
 */
Vue.component('user-menu', {
  template:
    '<div class="user-menu-list">\n' +
    '    <div class="group " v-for="(item,index) in menuList" :key="index">\n' +
    '      <div class="title" v-if="item.children" :class="{active:openList.includes(item.type)}" @click="openSubList(item)">\n' +
    '        <div>\n' +
    '            <span v-if="item.icon" class="iconfont" :class="item.icon"></span>\n' +
    '            <span>{{item.title}}</span>\n' +
    '            <span class="red-spot" v-if="item.type==7&&isSpot"></span>\n' +
    '        </div>\n' +
    '        <span class="arrow iconfont icon-xiangxia1"></span>\n' +
    '      </div>\n' +
    '      <div class="title" v-else :class="{select:type==item.type}" @click="selectMenu(item)">\n' +
    '        <div>\n' +
    '            <span v-if="item.icon" class="iconfont" :class="item.icon"></span>\n' +
    '            <span>{{item.title}}</span>\n' +
    '            <span class="red-spot" v-if="item.type==7&&isSpot"></span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="s-list" v-show="openList.includes(item.type)" v-if="item.children">\n' +
    '        <div class="g" v-for="(it,i) in item.children" :key="i">\n' +
    '          <div class="title" :class="{select:type==it.type}" @click="selectMenu(it)">{{it.title}}</div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '</div>',
  props: {
    type: {
      type: [String, Number]
    },
    openType: {
      type: Array,
    },

  },
  watch: {

  },
  created() {
    if (this.openType) {
      this.openList = [].concat(this.openType)
    }
    this.isSpot = 2 || this.isLogin;
    var that = this;

    window.eventBus.$on('loginSuccess', function (data) {
      that.userInfo = data
      if (data.auth == 1) {
        var obj = {
          title: '我的教学',
          type: '10',
          value: 'notice',
          icon: 'icon-jiaoxuejiaoyu',
          children: [
            {
              title: '我的视频',
              type: '10-1',
              value: 'videoTeaching',
            },
            {
              title: '我的课程',
              type: '10-2',
              value: 'courseTeaching',
            }
          ]
        }
        that.menuList.push(obj)
      }
    })

  },
  data: function () {
    return {
      userInfo: {},
      menuList: [
        {
          title: '我的学习',
          type: '1',
          value: 'study',
          icon: 'icon-kecheng1',
        },
        {
          title: '我的资料',
          type: '2',
          value: 'material',
          icon: 'icon-zhaoziliao',
        },
        {
          title: '我的问答',
          type: '3',
          value: 'questions',
          icon: 'icon-wenda',
        },
        {
          title: '我的订单',
          type: '4',
          value: 'order',
          icon: 'icon-wodedingdan',
        },
        {
          title: '基本资料',
          type: '5',
          value: 'info',
          icon: 'icon-gerenzhongxin',
        },
        // {
        //   title: '申请教师',
        //   type: '6',
        //   value: 'teacher',
        //   icon: 'icon-shenqing',
        // },
        {
          title: '消息中心',
          type: '7',
          value: 'notice',
          icon: 'icon-xiaoxi2',
        },
        {
          title: '学习计划',
          type: '8',
          value: 'studyPlan',
          icon: 'icon-xuexijihua',
        },
        {
          title: '我的VIP',
          type: '9',
          value: 'vip',
          icon: 'icon-vip',
        },

      ],
      openList: [],
      isSpot: false,
    }
  },
  methods: {
    openSubList: function (item) {
      var value = item.type.toString();
      var openList = this.openList;
      var index = openList.indexOf(value);
      if (index > -1) {
        openList.splice(index, 1)
      } else {
        openList.push(value)
      }
      this.openList = openList;
    },
    selectMenu: function (item) {
      var value = item.type.toString();
      if (this.type == value) { return }
      // this.type = value;
      window.location.href = '/user/' + item.value + '.html'
    },
  }
})


/**
 *企业中心的左侧导航
 *
 */
Vue.component('enterprise-menu', {
  template:
    '<div class="enterprise-menu-list">\n' +
    '    <div class="group " v-for="(item,index) in menuList" :key="index">\n' +
    '      <div class="title" v-if="item.children" :class="{active:openList.includes(item.type)}" @click="openSubList(item)">\n' +
    '        <div>\n' +
    '            <span v-if="item.icon" class="iconfont" :class="item.icon"></span>\n' +
    '            <span>{{item.title}}</span>\n' +
    '        </div>\n' +
    '        <span class="arrow iconfont icon-xiangxia1"></span>\n' +
    '      </div>\n' +
    '      <div class="title" v-else :class="{select:type==item.type}" @click="selectMenu(item)">\n' +
    '        <div>\n' +
    '            <span v-if="item.icon" class="iconfont" :class="item.icon"></span>\n' +
    '            <span>{{item.title}}</span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="s-list" v-show="openList.includes(item.type)" v-if="item.children">\n' +
    '        <div class="g" v-for="(it,i) in item.children" :key="i">\n' +
    '          <div class="title" :class="{select:type==it.type}" @click="selectMenu(it)">{{it.title}}</div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '</div>',
  props: {
    type: {
      type: [String, Number],
    },
    openType: {
      type: Array,
    },
  },
  watch: {

  },
  created() {
    if (this.openType) {
      this.openList = [].concat(this.openType)
    }
  },
  data: function () {
    return {
      menuList: [
        {
          title: '组织管理',
          type: '1',
          value: 'organization',
          children: [
            {
              title: '部门管理',
              type: '1-1',
              value: 'dep',
            },
            {
              title: '人员管理',
              type: '1-2',
              value: 'staff',
            },
            {
              title: '角色管理',
              type: '1-3',
              value: 'role',
            }
          ]
        },
        {
          title: '课程管理',
          type: '2',
          value: 'course',
        },
        {
          title: '学习管理',
          type: '3',
          value: 'study',
          children: [
            {
              title: '学习计划管理',
              type: '3-1',
              value: 'planStudy',
            },
            {
              title: '员工学习报表',
              type: '3-2',
              value: 'personnelStudy',
            },
          ]
        },
        {
          title: '我的订单',
          type: '5',
          value: 'order',
        },
        {
          title: '我的VIP',
          type: '6',
          value: 'vip',
        },
        {
          title: '设置',
          type: '4',
          value: 'set',
        },
      ],
      openList: []
    }
  },
  methods: {
    openSubList: function (item) {
      var value = item.type.toString();
      var openList = this.openList;
      var index = openList.indexOf(value);
      if (index > -1) {
        openList.splice(index, 1)
      } else {
        openList.push(value)
      }
      this.openList = openList;
    },
    selectMenu: function (item) {
      var value = item.type.toString();
      if (this.type == value) { return }
      // this.type = value;
      window.location.href = '/enterprise/' + item.value + '.html'
    },
  }
})


/**
 *关于我们的左侧导航
 *
 */
Vue.component('about-menu', {
  template:
    '<div class="about-menu-list">\n' +
    '    <div class="group " v-for="(item,index) in menuList" :key="index">\n' +
    '      <div class="title" v-if="item.children" :class="{active:openList.includes(item.type)}" @click="openSubList(item)">\n' +
    '        <div>\n' +
    '            <span v-if="item.icon" class="iconfont" :class="item.icon"></span>\n' +
    '            <span>{{item.title}}</span>\n' +
    '            <span class="red-spot" v-if="item.type==7&&isSpot"></span>\n' +
    '        </div>\n' +
    '        <span class="arrow iconfont icon-xiangxia1"></span>\n' +
    '      </div>\n' +
    '      <div class="title" v-else :class="{select:type==item.type}" @click="selectMenu(item)">\n' +
    '        <div>\n' +
    '            <span v-if="item.icon" class="iconfont" :class="item.icon"></span>\n' +
    '            <span>{{item.title}}</span>\n' +
    '            <span class="red-spot" v-if="item.type==7&&isSpot"></span>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '      <div class="s-list" v-show="openList.includes(item.type)" v-if="item.children">\n' +
    '        <div class="g" v-for="(it,i) in item.children" :key="i">\n' +
    '          <div class="title" :class="{select:type==it.type}" @click="selectMenu(it)">{{it.title}}</div>\n' +
    '        </div>\n' +
    '      </div>\n' +
    '    </div>\n' +
    '</div>',
  props: {
    type: {
      type: [String, Number],
      required: true
    },
    openType: {
      type: Array,
    },

  },
  watch: {

  },
  created() {
    if (this.openType) {
      this.openList = [].concat(this.openType)
    }
    this.isSpot = 2 || this.isLogin;
  },
  data: function () {
    return {
      menuList: [
        {
          title: '公司简介',
          type: '1',
          value: 'about',
        },
        {
          title: '联系我们',
          type: '2',
          value: 'contact',
        },
      ],
      openList: [],
      isSpot: false,
    }
  },
  methods: {
    openSubList: function (item) {
      var value = item.type.toString();
      var openList = this.openList;
      var index = openList.indexOf(value);
      if (index > -1) {
        openList.splice(index, 1)
      } else {
        openList.push(value)
      }
      this.openList = openList;
    },
    selectMenu: function (item) {
      var value = item.type.toString();
      if (this.type == value) { return }
      // this.type = value;
      window.location.href = '/help/' + item.value + '.html'
    },
  }
})


/**
 *弹窗
 *
 */
Vue.component('popup', {
  template:
    '<transition name="popup">\n' +
    '    <div v-if="show" class="popup-mask">\n' +
    '        <div class="mask"  @click="close"></div>\n' +
    '        <div class="slot-content" :style="{width:width,top:top}">\n' +
    '            <slot></slot>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</transition>',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    closeClickModal: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '60%'
    },
    top: {
      type: String,
      default: '20%'
    },
    tobody: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {

    }
  },
  created() {

  },
  mounted() {
    if (this.tobody) {
      document.body.appendChild(this.$el)
    }
  },
  methods: {
    close() {
      if (!this.closeClickModal) {
        return
      }
      this.$emit('close')
      this.$emit('update:show', false)
    }
  }
})

/**
 *抽屉
 *
 */
Vue.component('drawer', {
  template:
    '<transition name="drawer">\n' +
    '    <div v-show="show" class="drawer-mask">\n' +
    '        <div class="mask"  @click="close"></div>\n' +
    '        <div :class="show?\'open\':\'\'" >\n' +
    '          <div class="slot-content" :class="[direction]" :style="{width:width}">\n' +
    '            <slot></slot>\n' +
    '          </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</transition>',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    closeClickModal: {
      type: Boolean,
      default: true
    },
    direction: {
      type: String,
      default: 'rtl',
      validator(val) {
        return ['ltr', 'rtl'].indexOf(val) !== -1;
      }
    },
    width: {
      type: String,
      default: '30%'
    },
    tobody: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {

    }
  },
  created() {

  },
  mounted() {
    if (this.tobody) {
      document.body.appendChild(this.$el)
    }
  },
  methods: {
    close() {
      if (!this.closeClickModal) {
        return
      }
      this.$emit('update:show', false)
    }
  }
})



/**
 *加载弹窗
 *
 */
Vue.component('loadding', {
  template:
    '<transition name="popup">\n' +
    '    <div v-if="show" class="loadding-mask">\n' +
    '        <div class="mask" ></div>\n' +
    '        <div class="loadding-content" >\n' +
    '          <svg viewBox="25 25 50 50" class="circular"><circle cx="50" cy="50" r="20" fill="none" class="path"></circle></svg>\n' +
    '          <div class="title">{{title}}</div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</transition>',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    title: {
      type: [String, Number],
      default: '加载中'
    },
    tobody: {
      type: Boolean,
      default: true
    }
  },
  data: function () {
    return {

    }
  },
  created() {

  },
  mounted() {
    if (this.tobody) {
      document.body.appendChild(this.$el)
    }
  },
  methods: {
  }
})



/**
 *登录
 *
 */
Vue.component('login-form', {
  template:
    '<popup :show.sync="loginPopup" tobody width="500px" :close-click-modal="false">\n' +
    '    <div class="login-content">\n' +
    '        <template v-if="type==1">\n' +
    '            <div class="top-box">\n' +
    '            <div class="fun-box">\n' +
    '                <div class="box"  :class="{active:loginType==1}" @click="setLoginType(1)">\n' +
    '                <div>账号登录</div>\n' +
    '                <div class="line"></div>\n' +
    '                </div>\n' +
    '                <div class="box" :class="{active:loginType==2}" @click="setLoginType(2)">\n' +
    '                <div>验证码登录</div>\n' +
    '                <div class="line"></div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '            <span class="iconfont icon-guanbi" @click="close"></span>\n' +
    '            </div>\n' +
    '            <template v-if="loginType==1"> \n' +
    '            <div class="input-box">\n' +
    '                <input class="input" type="text" placeholder="请输入登录手机号码" v-model="loginPhone" maxlength="11" minlength="11" @input="registerPhoneInput(\'loginPhone\')" @keyup.enter="phonmeLoginTap">\n' +
    '                <div class="tips" v-if="loginPhoneTips">{{loginPhoneTips}}</div>\n' +
    '            </div>\n' +
    '            <div class="input-box">\n' +
    '                <input class="input" :type="isEye?\'text\':\'password\'" placeholder="请输入密码" v-model="loginPassword" @input="verificationInput(\'loginPassword\',\'请输入密码\')" @keyup.enter="phonmeLoginTap">\n' +
    '                <span class="iconfont icon-yanjing" v-if="!isEye" @click="isEye=true"></span>\n' +
    '                <span class="iconfont icon-biyanjing" v-else @click="isEye=false"></span>\n' +
    '                <div class="tips" v-if="loginPasswordTips">{{loginPasswordTips}}</div>\n' +
    '            </div>\n' +
    '            <div class="forget-password"><span class="span" @click="setType(3)">忘记密码</span></div>\n' +
    '            <button class="login-btn" @click="phonmeLoginTap">登录</button>\n' +
    '            </template>\n' +
    '            <template v-else-if="loginType==2">\n' +
    '            <div class="input-box">\n' +
    '                <input class="input" type="text" placeholder="短信登录仅限中国大陆用户" v-model="codePhone" maxlength="11" minlength="11" @input="registerPhoneInput(\'codePhone\')" @keyup.enter="codeLoginTap">\n' +
    '                <div class="tips" v-if="codePhoneTips">{{codePhoneTips}}</div>\n' +
    '            </div>\n' +
    '            <div class="input-box">\n' +
    '                <input class="input" type="text" placeholder="请输入短信验证码" v-model="codeWebcode" @input="verificationInput(\'codeWebcode\',\'请输入短信验证码\')" @keyup.enter="codeLoginTap">\n' +
    '                <div class="code" :class="{gray:sendValue1}" @click="openCodePopup"> {{ sendValue1 ? codeTitle1 : "获取验证码" }}</div>\n' +
    '                <div class="tips" v-if="codeWebcodeTips">{{codeWebcodeTips}}</div>\n' +
    '            </div>\n' +
    '            <button class="login-btn" @click="codeLoginTap" style="margin-top: 52px;">登录</button>\n' +
    '            </template>\n' +
    '        </template>\n' +
    '        <template v-else-if="type==2">\n' +
    '        <div class="top-box">\n' +
    '        <div class="register-title">快速注册</div>\n' +
    '        <span class="iconfont icon-guanbi" @click="loginPopup=false"></span>\n' +
    '        </div>\n' +
    '        <div class="input-box">\n' +
    '        <input class="input" type="text" placeholder="请输入手机号" v-model="registerPhone" @input="registerPhoneInput(\'registerPhone\')" maxlength="11" minlength="11">\n' +
    '        <div class="tips" v-if="registerPhoneTips">{{registerPhoneTips}}</div>\n' +
    '        </div>\n' +
    '        <div class="input-box">\n' +
    '        <input class="input" type="text" placeholder="请输入短信验证码" v-model="registerWebcode" @input="verificationInput(\'registerWebcode\',\'请输入短信验证码\')">\n' +
    '        <div class="code" :class="{gray:sendValue2}" @click="openCodePopup"> {{ sendValue2 ? codeTitle2 : "获取验证码" }}</div>\n' +
    '        <div class="tips" v-if="registerWebcodeTips">{{registerWebcodeTips}}</div>\n' +
    '        </div>\n' +
    '        <div class="agreement-check">\n' +
    '        <input class="checkbox" type="checkbox" v-model="isAgreement" >\n' +
    '        <div class="agreement" style="margin: 0;cursor: pointer;" >\n' +
    '            <div style="padding-left: 10px;" @click="isAgreement=!isAgreement">我已阅读并同意</div>\n' +
    '            <a class="blue" href="/agreement.html" target="_blank">《安全服务网注册协议》</a>\n' +
    '        </div>\n' +
    '        <div class="tips" v-if="isAgreementTips">{{isAgreementTips}}</div>\n' +
    '        </div>\n' +
    '        <button class="login-btn" @click="registerTap">立即注册</button>\n' +
    '    </template>\n' +
    '    <template v-if="type==1||type==2">\n' +
    '        <div class="register-text">\n' +
    '        <button class="text" v-if="type==1" @click="setType(2)">快速注册</button>\n' +
    '        <button class="text" v-else-if="type==2" @click="setType(1)">返回登录</button>\n' +
    '        </div>\n' +
    '        <div class="agreement">\n' +
    '        <div>登录即同意安全服务网</div>\n' +
    '        <a class="blue" href="/agreement.html" target="_blank">《隐私政策》</a>\n' +
    '        </div>\n' +
    '    </template>\n' +
    '    <template v-if="type==3">\n' +
    '        <div class="top-box">\n' +
    '        <div class="register-title">忘记密码</div>\n' +
    '        <span class="iconfont icon-guanbi" @click="loginPopup=false"></span>\n' +
    '        </div>\n' +
    '        <div class="input-box">\n' +
    '        <input class="input" type="text" placeholder="请输入手机号" v-model="forgetPhone" @input="registerPhoneInput(\'forgetPhone\')" maxlength="11" minlength="11">\n' +
    '        <div class="tips" v-if="forgetPhoneTips">{{forgetPhoneTips}}</div>\n' +
    '        </div>\n' +
    '        <div class="input-box">\n' +
    '        <input class="input" type="text" placeholder="请输入短信验证码" v-model="forgetWebCode" @input="verificationInput(\'forgetWebCode\',\'请输入短信验证码\')">\n' +
    '        <div class="code" :class="{gray:sendValue3}" @click="openCodePopup"> {{ sendValue3 ? codeTitle3 : "获取验证码" }}</div>\n' +
    '        <div class="tips" v-if="forgetWebCodeTips">{{forgetWebCodeTips}}</div>\n' +
    '        </div>\n' +
    '        <div class="input-box">\n' +
    '        <input class="input" type="text" placeholder="请输入密码" v-model="forgetPassword" @input="verificationPasswodInput(\'forgetPassword\',\'请输入密码\')" minlength="6" maxlength="16">\n' +
    '        <div class="tips" v-if="forgetPasswordTips">{{forgetPasswordTips}}</div>\n' +
    '        </div>\n' +
    '        <div class="input-box">\n' +
    '        <input class="input" type="text" placeholder="请再次输入密码" v-model="forgetAgainPassword" @input="verificationPasswodInput(\'forgetAgainPassword\',\'请再次输入密码\')" minlength="6" maxlength="16">\n' +
    '        <div class="tips" v-if="forgetAgainPasswordTips">{{forgetAgainPasswordTips}}</div>\n' +
    '        </div>\n' +
    '        <button class="login-btn" @click="forgetTap"  style="margin-top: 45px;">确认</button>\n' +
    '        <div style="height: 20px;"></div>\n' +
    '    </template>\n' +
    '    <verification-code :cshow.sync="codePopup" :type="codeVerifyType" :phone="codeVerifyPhone" @success="verificationSuccess"></verification-code>\n' +
    '    </div>\n' +
    '</popup>',
  props: {
    vshow: {
      type: Boolean,
      default: false
    },
    typeProp: {
      type: [String, Number],
      default: 1
    },
    loginTypeProp: {
      type: [String, Number],
      default: 1
    },
    needlogin: {
      type: [Boolean],
      default: false,
    }
  },
  watch: {
    vshow: {
      handler: function (val) {
        this.loginPopup = val;
      },
      immediate: true,
    },
    loginPopup(val) {
      this.$emit('update:vshow', val)
    },
    typeProp: {
      handler: function (val) {
        this.type = val;
      },
      immediate: true
    },
    loginTypeProp: {
      handler: function (val) {
        this.loginType = val;
      },
      immediate: true
    }
  },
  data: function () {
    return {
      type: 1,
      loginType: 1,
      codeVerifyType:'login',
      codeVerifyPhone:'',

      loginPopup: false,
      isEye: false,
      isAgreement: false,
      codePopup: false,

      sendValue1: false,
      codeTitle1: "60秒后重新发送",
      codeCount1: 60,
      timer1: null,

      sendValue2: false,
      codeTitle2: "60秒后重新发送",
      codeCount2: 60,
      timer2: null,

      sendValue3: false,
      codeTitle3: "60秒后重新发送",
      codeCount3: 60,
      timer3: null,


      registerPhone: '',
      registerWebcode: '',
      registerPhoneTips: '',
      registerWebcodeTips: '',
      isAgreementTips: '',

      loginPhone: '',
      loginPassword: '',
      loginPhoneTips: '',
      loginPasswordTips: '',

      codePhone: '',
      codeWebcode: '',
      codePhoneTips: '',
      codeWebcodeTips: '',

      forgetPhone: '',
      forgetWebCode: '',
      forgetPassword: '',
      forgetAgainPassword: '',
      forgetPhoneTips: '',
      forgetWebCodeTips: '',
      forgetPasswordTips: '',
      forgetAgainPasswordTips: '',

      regPhone: /^1(3|4|5|6|7|8|9)\d{9}$/,

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    close() {
      this.loginPopup = false;
      if (this.needlogin) {
        window.location.href = '/'
      }
    },
    setLoginType(loginType) {
      if (this.loginType == loginType) { return }
      this.loginType = loginType;
      this.$emit('update:loginTypeProp', loginType)
    },
    setType(type) {
      if (this.type == type) { return }
      this.type = type;
      this.$emit('update:typeProp', type)
    },
    openCodePopup(){
      var prefix = 'codePhone'
      if(this.type==1){
        this.codeVerifyPhone = this.codePhone;
        this.codeVerifyType = 'login'
      }else if(this.type==2){
        this.codeVerifyPhone = this.registerPhone;
        prefix = 'registerPhone'
        this.codeVerifyType = 'register'
      }else if(this.type==3){
        this.codeVerifyPhone = this.forgetPhone;
        prefix = 'forgetPhone'
        this.codeVerifyType = 'forget'
      }
      if(!this.registerPhoneInput(prefix)){
        return
      }
      this.codePopup = true;
    },
    verificationSuccess(){
      this.sendCode(this.type)
      this.codePopup = false;

    },
    sendCode: function (type = 1) {
      var that = this;
      var mobile = this.mobile;
      var regMobile = this.regPhone;
      if (this['sendValue' + type]) return;
      this['sendValue' + type] = true;
      this['timer' + type] = setInterval(function () {
        var codeCount = (that['codeCount' + type] = that['codeCount' + type] - 1);
        if (codeCount < 0) {
          codeCount = 60;
          that['codeCount' + type] = 60;
          that['sendValue' + type] = false;
          that['codeTitle' + type] = '60秒后重新发送';
          clearInterval(that['timer' + type]);
        }
        that['codeTitle' + type] = codeCount + "秒后重新发送";
      }, 1000);

    },


    setFunType(funType) {
      if (this.funType == funType) { return }
      this.funType = funType;
    },
    provinceChange(e) {
      var index = e.index;
      this.provinceIndex = index;
      this.cityList = this.provinceList[index].children;
      this.city = ''

      this.areaList = [];
      this.area = ''
    },
    cityChange(e) {
      var index = e.index;
      this.cityIndex = index;
      this.areaList = this.cityList[index].children;
      this.area = ''
    },
    areaChange(e) {
      var index = e.index;
      this.areaIndex = index;
    },
    selectInterest: function (index) {
      var item = this.interestList[index];
      item.select = !item.select
      this.$set(this.interestList, index, item)
    },

    registerPhoneInput(type = 'registerPhone') {
      if (this[type]) {
        if (!this.regPhone.test(this[type])) {
          this[type + 'Tips'] = '请输入正确的手机号'
          return false
        } else {
          this[type + 'Tips'] = ''
          return true
        }
      } else {
        this[type + 'Tips'] = '请输入手机号'
        return false
      }
    },
    verificationInput(type, tipsText) {
      if (this[type]) {
        this[type + 'Tips'] = ''
        return true
      } else {
        this[type + 'Tips'] = tipsText
        return false
      }
    },
    verificationPasswodInput(type, tipsText) {
      if (this[type]) {
        if (this[type].length >= 6 && this[type].length <= 16) {
          this[type + 'Tips'] = ''
          return true
        } else {
          this[type + 'Tips'] = '请输入6-16位字符'
          return false
        }
      } else {
        this[type + 'Tips'] = tipsText
        return false
      }
    },
    confirmPasswodInput(type, type2, tipsText) {
      if (this[type]) {
        if (this[type] == this[type2]) {
          this[type + 'Tips'] = ''
          return true
        } else {
          this[type + 'Tips'] = '两次密码不一致'
          return false
        }
      } else {
        this[type + 'Tips'] = tipsText
        return false
      }
    },
    registerTap() {
      var that = this;
      var arr = [this.registerPhoneInput(), this.verificationInput('registerWebcode', '请输入短信验证码'), this.verificationInput('isAgreement', '请勾选注册协议')];
      if (arr.includes(false)) { return }
      var data = {
        phone: this.registerPhone,
        webcode: this.registerWebcode,
      }
      axios.post('/user/register', data).then(function (res) {
        // message.default.success('注册成功')
        if (res.status == 100) {
          var Authorization = res.data;
          localStorage.setItem('Authorization', Authorization)
          that.loginPopup = false;
          window.location.reload();
        } else {
          message.default.error(res.message)
        }
      }).catch(function (err){
        message.default.error(err.message)
      })
    },




    loginTap() {
      axios.get('/login', { params: {} }).then(function (res) {
      })
    },
    phonmeLoginTap() {
      var arr = [this.registerPhoneInput('loginPhone'), this.verificationInput('loginPassword', '请输入密码')];
      if (arr.includes(false)) { return }
      var data = {
        phone: this.loginPhone,
        password: this.loginPassword,
      }
      var that = this;
      axios.post('/user/login', data).then(function (res) {
        if (res.status == 100) {
          var Authorization = res.data;
          localStorage.setItem('Authorization', Authorization)
          that.loginPopup = false;
          window.location.reload();
        } else {
          message.default.error(res.message)
        }
      }).catch(function (err){
        message.default.error(err.message)
      })
    },
    codeLoginTap() {
      var arr = [this.registerPhoneInput('codePhone'), this.verificationInput('codeWebcode', '请输入短信验证码')];
      if (arr.includes(false)) { return }
      var data = {
        phone: this.codePhone,
        webcode: this.codeWebcode,
      }
      var that = this;
      axios.post('/user/verifyLogin', data).then(function (res) {
        if (res.status == 100) {
          var Authorization = res.data;
          localStorage.setItem('Authorization', Authorization)
          that.loginPopup = false;
          window.location.reload();
        } else {
          message.default.error(res.message)
        }
      }).catch(function (err){
        message.default.error(res.message)
      })
    },
    forgetTap() {
      var arr = [this.registerPhoneInput('forgetPhone'), this.verificationInput('forgetWebCode', '请输入短信验证码'), this.verificationPasswodInput('forgetPassword', '请输入密码'), this.verificationPasswodInput('forgetAgainPassword', '请再次输入密码')];
      if (arr.includes(false)) { return }

      var data = {
        phone: this.forgetPhone,
        webcode:this.forgetWebCode,
        newPwd: this.forgetPassword,
      }
      var that = this;
      axios.post('/user/forgetPwd', data).then(function (res) {
        if (res.status == 100) {
          message.default.success(res.message)
          that.type = 1 ;
        } else {
          message.default.error(res.message)
        }
      }).catch(function (err){
        message.default.error(res.message)
      })
    },
  }
})


/**
 *验证码
 *
 */
Vue.component('verification-code', {
  template:
    '    <popup :show.sync="codePopup" tobody width="500px" :close-click-modal="false">\n' +
    '        <div class="verification-code">\n' +
    '        <span class="iconfont icon-guanbi close" @click="codePopup=false"></span>\n' +
    '        <div class="img-box">\n' +
    '            <img class="bg" :src="bgImg" alt="" @load="bgImgSuccess" >\n' +
    '            <img class="img" :style="{left:codeleft,top:posY+\'px\'}" @load="targetImgSuccess"  :src="targetImg" alt="" @mousedown="sliderDown"\n' +
    '            @touchstart="sliderDown">\n' +
    '            <span class="iconfont icon-shuaxin refresh" @click="getImg"></span>\n' +
    '            <div class="loadding-box" v-if="loadding">\n' +
    '               <span class="iconfont icon-jiazai loadding"></span>\n' +
    '               <span class="text">拼命加载中</span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="slider-box">\n' +
    '            <div class="tips">向右滑动验证</div>\n' +
    '            <div class="speed" :class="\'type\'+codeType" :style="{width:codeleft}"></div>\n' +
    '            <div class="slider" :class="\'type\'+codeType" :style="{left:codeleft}" @mousedown="sliderDown" @touchstart="sliderDown">\n' +
    '            <span class="iconfont icon-jiantou_xiangyou" v-if="codeType==1||codeType==2"></span>\n' +
    '            <span class="iconfont icon-duihao4" v-else-if="codeType==3"> </span>\n' +
    '            <span class="iconfont icon-guanbi" v-else-if="codeType==4"></span>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        </div>\n' +
    '    </popup>',
  props: {
    cshow: {
      type: Boolean,
      default: false
    },
    type:{
      type: [String,Number],
      default: ''
    },
    phone:{
      type: [String,Number],
      default: ''
    }
  },
  data: function () {
    return {
      codePopup: false,
      codeType: 1,//1初始 2过程中 3成功 4失败
      codeleft: '0',
      originX: 0,
      originY: 0,
      disX: 0,
      disY: 0,
      consumingTime: '',

      targetImg:'',
      bgImg:'',

      posX:'',
      posY:'',

      loadding:true,
      bgImgLoadding:true,
      targetImgLoadding:true,
    }
  },
  watch: {
    cshow: {
      handler: function (val) {
        this.codePopup = val;
        if(val){
          this.getImg();
        }
      },
      immediate: true,
    },
    codePopup(val) {
      this.$emit('update:cshow', val)
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    sliderDown(e) {
      this.originX = e.changedTouches ? e.changedTouches[0].pageX : e.pageX;
      this.originY = e.changedTouches ? e.changedTouches[0].pageY : e.pageY;
      this.codeType = 2;
      this.timestamp = +new Date();
      document.addEventListener("mousemove", this.domMove);
      document.addEventListener("mouseup", this.domUp);
      document.addEventListener("touchmove", this.domMove);
      document.addEventListener("touchend", this.domUp);
    },
    domMove(e) {
      var x = e.changedTouches ? e.changedTouches[0].pageX : e.pageX
      var y = e.changedTouches ? e.changedTouches[0].pageY : e.pageY
      var disX = x - this.originX;
      this.disY = y - this.originY;
      disX = disX >= 0 ? disX : 0;
      this.disX = disX >= 434 ? 434 : disX;
      this.codeleft = this.disX + 'px';
    },
    domUp(e) {
      var that = this;
      this.timestamp = +new Date() - this.timestamp;
      this.consumingTime = '验证通过耗时' + (this.timestamp / 1000).toFixed(1) + '秒';

      // console.log(this.codeleft,'codeleft')
      // console.log(this.codeleft,'codeleft')
      var formData = {
        "posX":this.disX,
        "posY":this.posY,
        "phone":this.phone,
        "type":this.type,
      }
      axios.post('/user/maskVerify',formData).then(function (res) {
        // console.log(res,'res')
        that.codeType = 3;
        that.$emit('success',{"phone":that.phone,type:that.type})
        message.default.success('验证成功')
      }).catch(function(err){
        that.codeType = 4;
        that.$emit('error')
        setTimeout(function() {
          that.getImg()
        }, 1000)
      })


      document.removeEventListener("mousemove", this.domMove);
      document.removeEventListener("mouseup", this.domUp);



      // top: 31px;
      // width: 92px;
      // height: 114px;
    },
    getImg(){
      var that = this;
      var formData = {
        "phone":this.phone,
	      "type":this.type        
      }
      this.disX = 0;
      this.disY = 0;
      this.codeleft = 0;
      this.codeType = 1;
      if(this.lock){return}
      this.lock = false;
      this.loadding = true;
      this.bgImgLoadding = true;
      this.targetImgLoadding = true;
      axios.post('/user/maskImage',formData).then(function (res) {
        // console.log(res,'res')
        var data = res.data;
        that.bgImg = data.bgBase64;
        that.targetImg = data.targetBase64;
        that.posX = data.posX ;
        that.posY = data.posY ;

        that.lock = false;
      }).catch(function(err){
        that.lock = false;
      })
    },



    bgImgSuccess(){
      // console.log(1,'1')
      this.bgImgLoadding = false;
      if(!this.targetImgLoadding){
        this.loadding = false;
      }
    },
    targetImgSuccess(){
      // console.log(2,'2')
      this.targetImgLoadding = false;
      if(!this.bgImgLoadding){
        this.loadding = false;
      }
    },
  }
})